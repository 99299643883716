@import 'mixins/breakpoints';

@mixin container-desktop-padding() {
    @include media-breakpoint-up(md) {
        padding: 0 0;
    }
}

@mixin continue-desktop-padding() {
    @include media-breakpoint-up(md) {
        padding: 0 0;
    }
}

@mixin scroll-bar() {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-thumb {
        background: var(--color-primary);
        width: 4px;
        border-radius: 5px;
    }

    &::-webkit-scrollbar {
        background: var(--color-secondary);
        width: 7px;
        height: 7px;
    }
}

@mixin secondary-scroll-bar() {
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar-thumb {
        background: var(--color-secondary);
        width: 4px;
        border-radius: 5px;
    }

    &::-webkit-scrollbar {
        background: var(--color-light-base);
        width: 7px;
    }
}

@mixin content-container() {
    width: 100%;
    max-width: 1100px;
}

@mixin cut-text-with-ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
