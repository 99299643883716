@import 'variables';
@import 'mixins';

@font-face {
    font-family: PlatformOLX-Bold;
    src: local(PlatformOLX-Bold), url(/fonts/PlatformOLX/PlatformOLX-Bold.otf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: PlatformOLX;
    src: local(PlatformOLX-Regular), url(/fonts/PlatformOLX/PlatformOLX-Regular.otf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

html {
    min-height: 100vh;
    width: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: $mainFont;
    margin: 0;
    min-height: 100vh;
    width: 100%;
    scroll-behavior: inherit;
    position: relative;
    display: flex;
   @include scroll-bar;
}

* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
ol,
ul,
li,
p {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    margin: 0;
    padding: 0;
}

:global {
    .visibleDesktop {
        @include media-breakpoint-only(xs) {
            display: none;
        }

        @include media-breakpoint-only(sm) {
            display: none;
        }
    }

    .visibleMobile {
        @include media-breakpoint-only(lg) {
            display: none;
        }

        @include media-breakpoint-only(md) {
            display: none;
        }
    }

    #root {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
