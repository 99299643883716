$mainFont: Poppins, sans-serif;

:root {
    /*  REFERENCE TO COLORS */
    --color-primary: var(--palette-dodger-blue);
    --color-secondary: var(--palette-dove-gray);
    --color-secondary-light: var(--palette-gray);
    --color-secondary-lighter: var(--palette-submarine-ray);
    --color-secondary-dark: var(--palette-label-gray);
    --color-secondary-dark-inactive: var(--palette-light-black);
    --color-light: var(--palette-porcelain-white);
    --color-light-base: var(--palette-white);
    --color-dark: var(--palete-deep-teal);
    --color-dark_variant-1: var(--palette-bismark);
    --color-dark_variant-2: var(--palette-light-bismark);
    --color-dark_variant-3: var(--palette-gray-label);
    --color-dark-light: var(--palette-tundora-dark);
    --color-dark-lighter-max: var(--palette-haze-black);
    --color-dark-black: var(--palette-elephant-dark);
    --color-sucess: var(--palette-green);
    --color-sucess-alt: var(--palette-green-deep);
    --color-danger: var(--palette-red);
    --color-special: var(--palette-hummingBird-special);
    --color-rejected: var(--palette-rejected);
    --color-background-rejected: var(--palette-light-red);
    --color-approved: var(--palette-approved);
    --color-pending: var(--palette-pending);
    --color-danger-alt: var(--palette-red-deep);
    --color-cyan: var(--palete-cyan);
    --color-active-chip: #c8f8f6;
    --color-success-notification: var(--palette-green-light);
    --color-info-notification: var(--palette-light-yellow);
    --color-error-notification: #ffd6c9;
    --color-warning-notification: rgba(255, 237, 178, 0.5);
    --background-light-bg-gray: var(--palette-bg-light-gray);

    /**
      *  COLORS
      *
      * ========================================================================= */

    /* WHITE */
    --palette-white: #ffffff;
    --palette-dark-white: #e2e2e2;
    --palette-porcelain-white: #ebeeef;
    --palette-light-white: #d5d5d5;
    --palette-lighter-white: #f6f6f6;
    --palette-error-background: #efe0e0;

    /* YELLOW */
    --palette-light-yellow: rgba(255, 237, 178, 0.5);
    --palette-info-yellow: #F1EBBB;

    /* TURQUOISE */
    --palette-turquoise: #23e5d8;

    /* BLACK */
    --palette-black: #000000;
    --palette-tundora-dark: #434343;
    --palette-haze-black: #f3f3f3;
    --palette-elephant-dark: #143f43;
    --palete-deep-teal: #002f34;
    --palette-bismark: rgba(0,47,52,0.5);
    --palette-light-bismark: rgb(30, 81, 86);
    --palette-light-black: #7f9799;

    /* BLUE */
    --palette-dodger-blue: #3a77ff;
    --palette-dodger-blue-alt: #3869d7;
    --palette-royal-blue: #2a65ea;
    --palette-light-blue: #81a1f6;
    --palette-lighter-blue: #cbdcfe;

    /* PURPLE */
    --palete-jacksons-purple: #21409a;

    /* GRAY */
    --palette-placeholder-gray: #c8c8c8;
    --palette-placeholder-gray-dealership: #7f9799;
    --palette-bg-light-gray: #f2f4f5;
    --palette-gray: #8a8a8a;
    --palette-cod-gray: #111111;
    --palette-dove-gray: #737373;
    --palette-submarine-ray: #bbc7c8;
    --palette-medium-gray: #565656;
    --palette-label-gray: #4c4c4c;
    --palette-light-gray: #48525c;
    --palette-lighter-gray: #969faa;
    --palette-lightermax-gray: #d5d9dd;
    --palette-dark-gray: #5a6978;
    --request-finished-background: #edf4ff;
    --palette-edward: #a9b5b6;
    --palette-pending: #d8d8d8;
    --palette-gray-label: #cbdcfe;

    --palette-overlay-gray: rgba(52, 63, 75, 0.53);

    /* RED */
    --palette-red: #d0111b;
    --palette-red-deep: #81111b;
    --palette-dark-red: #d0111b;
    --palette-rejected: #a51915;
    --palette-rejected-overlay: rgba(165,25,21,0.9);
    --palette-light-red: #fff2f2;
    --palette-background-red: #ffd6c9;

    /* GREEN */
    --palette-green: #13ce66;
    --palette-green-deep: #139249;
    --palette-approved: #00a49f;
    --palette-approved-overlay: rgba(52,167,72,0.5);
    --palette-green-overlay: rgba(21, 64, 69, 0.8);
    --palette-green-light: #d4f8f1;

    /* SPECIALS */
    --palette-hummingBird-special: #d4f9f1;
    --palete-cyan: #23e4db;

    // UI COMPONENTS COLORS
    --desktop-background-top: #e0e5f0;
    --desktop-background-bottom: #f2f2f2;
    --background-images-color: #f4f6f6;
    --background-main: #f2f2f2;

    /**
          *  FONTS
          *
          * ========================================================================= */
    --main-font: Poppins, sans-serif;

    /**
      *  SIZES
      *
      * ========================================================================= */
    --content-width-md: 480px;
    --content-min-height-md: 497px;

    --layout-body-padding-x: 14px;
    --layout-body-padding-y: 40px;
    --layout-footer-padding-y: 10px;

    --modal-form-padding: 0 13px 10px;

    --border-radius-xs: 4px;
    --border-radius-sm: 5px;
    --border-radius-md: 8px;
    --border-radius-lg: 12px;
    --border-radius-xl: 999999px;

    /**
      * SIZES - COMPONENTS
      *
      * ========================================================================= */
    --input-height-sm: 38px;
    --input-height-md: 45px;

    --input-height-dealership: 48px;

    /**
      * FONT SIZES
      *
      * ========================================================================= */
    --font-x-xs: 10px;
    --font-xs: 12px;
    --font-sm: 14px;
    --font-md: 16px;
    --font-lg: 18px;
    --font-xl: 20px;
    --font-x-xl: 24px;
    --font-xx-xl: 26px;

    --header-height-mobile: 48px;
    --header-height-desktop: 72px;
}
